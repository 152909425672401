/* Hind font family */
@font-face {
  font-family: 'Hind';
  src: url('/src/assets/fonts/Hind/Hind-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Hind';
  src: url('/src/assets/fonts/Hind/Hind-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Hind';
  src: url('/src/assets/fonts/Hind/Hind-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Hind';
  src: url('/src/assets/fonts/Hind/Hind-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Hind';
  src: url('/src/assets/fonts/Hind/Hind-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Volkhov font family */
@font-face {
  font-family: 'Volkhov';
  src: url('/src/assets/fonts/Volkhov/Volkhov-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Volkhov';
  src: url('/src/assets/fonts/Volkhov/Volkhov-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Volkhov';
  src: url('/src/assets/fonts/Volkhov/Volkhov-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Volkhov';
  src: url('/src/assets/fonts/Volkhov/Volkhov-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* UseflowIcons font family */
@font-face {
  font-family: 'UseflowIcons';
  src: url('/src/assets/fonts/UseflowIcons/UseflowIcons.ttf') format('truetype');
  font-style: normal;
}
